<mat-dialog-content>
  <form [formGroup]="form" class="row">
    <h3>Event</h3>
    <mat-form-field class="is-dense form-field" appearance="outline">
      <mat-label>Start Date Time</mat-label>
      <input matInput
             placeholder
             formControlName="start"
             type="datetime-local"/>
      <mat-error *ngIf="form.controls.start.invalid">
        {{ getErrorMessage(form.controls.start) }}
      </mat-error>
    </mat-form-field>

    <div class="col-6">
      <mat-form-field appearance="outline">
        <mat-label>Process Step Id</mat-label>
        <input matInput formControlName="processStepId">
        <mat-error *ngIf="form.controls.processStepId.invalid">
          {{ getErrorMessage(form.controls.processStepId) }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Duration (minutes)</mat-label>
        <input matInput formControlName="duration" type="number" min="0">
        <mat-error *ngIf="form.controls.duration.invalid">
          {{ getErrorMessage(form.controls.duration) }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-6">
      <mat-form-field appearance="outline">
        <mat-label>Class</mat-label>
        <mat-select formControlName="class">
          <mat-option *ngFor="let option of allClasses" [value]="option.value">
            {{ option.display }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.class.invalid">
          {{ getErrorMessage(form.controls.class) }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Reason</mat-label>
        <mat-select formControlName="reason">
          <mat-option *ngFor="let option of allReasons" [value]="option.value">
            {{ option.display }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!allReasons?.length || form.controls.reason.invalid">
          {{ !allReasons?.length ? "No reasons related to selected class" : getErrorMessage(form.controls.reason) }}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="btn-row">
    <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="form.invalid">Submit</button>
  </div>
</mat-dialog-content>
